import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { SnackbarProvider, enqueueSnackbar } from 'notistack'

const Contact = () => {
  const [loading, setLoading] = useState(false)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data, e) => {
    setLoading(true)
    console.log("Message submited: " + JSON.stringify(data));
    try {
      e.target.reset();
      enqueueSnackbar('Message sent succesfully!!', { variant: 'success',anchorOrigin: {
        vertical: 'top',
        horizontal: 'right'
      } })
      axios.defaults.headers.post['Content-Type'] = 'application/json';
      setLoading(false)

      const response = await axios.post(`https://formsubmit.co/ajax/femiadeniyi.contact@gmail.com`, data)
     
      console.log(response)
      // document.getElementById('application-form').reset()

    } catch (err) {
      console.log(err)
      setLoading(false)

    }
  };

  return (
    <>
      <SnackbarProvider />
      <form className="contactform" onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register("name", { required: true })}
                type="text"
                name="name"
                placeholder="YOUR NAME"
              />
              {errors.name && errors.name.type === "required" && (
                <span className="invalid-feedback">Name is required</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-6">
            <div className="form-group">
              <input
                {...register(
                  "email",
                  {
                    required: "Email is Required",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Entered value does not match email format",
                    },
                  },
                  { required: true }
                )}
                type="email"
                name="email"
                placeholder="YOUR EMAIL"
              />
              {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12 col-md-12">
            <div className="form-group">
              <input
                {...register("subject", { required: true })}
                type="text"
                name="subject"
                placeholder="YOUR SUBJECT"
              />
              {errors.subject && (
                <span className="invalid-feedback">Message is required.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <div className="form-group">
              <textarea
                {...register("message", { required: true })}
                name="message"
                placeholder="YOUR MESSAGE"
              ></textarea>
              {errors.message && (
                <span className="invalid-feedback">Message is required.</span>
              )}
            </div>
          </div>
          {/* End .col */}

          <div className="col-12">
            <button type="submit" className="button"
              disabled={loading}
            >
              <span className="button-text">{loading ? 'Sending Message' : 'Send Message'}</span>
              <span className="button-icon fa fa-send"></span>
            </button>
          </div>
          {/* End .col */}
        </div>
      </form>

      {/* End contact */}
    </>
  );
};

export default Contact;
