import React from "react";

const experienceContent = [
  {
    year: "February 2023 - present",
    position: "Senior Frontend Developer",
    compnayName: "Genti Media",
    details: `Currently spearheading the development of a robust system, enabling content creators to seamlessly submit their contents and enhancing collaboration among the creators.  Developed Genti media platform, to resonate with African storytellers seeking broad reach and audiences craving African narratives and stories, this positioned Genti Audio as a prominent player in the realm of African audio stories as it catapults the user base from less than 500 to over 10,000 within 5 months. This achievement underscores the platform's appeal and the accessibility.`,
  }
  ,
  {
    year: "January 2021 - present",
    position: "Frontend Developer (Contract)",
    compnayName: "Skill seeds Limited",
    details: ` Actively contributing to the development of external projects and in-house projects undertaken by our team. Collaborating with clients and project stakeholders in translating project requirements into polished user interfaces, while working closely with cross-functional teams to ensure seamless project execution.`,
  },
  {
    year: "August 2020 - November 2020",
    position: "Frontend Developer (intern)",
    compnayName: "HNG",
    details: `Contributed to various stages of the systems development lifecycle, from requirement gathering to successful production releases. Engaging in field testing, I verified project performance and ensured the delivery of high-quality solutions.`,
  }
  
];

const Experience = () => {
  return (
    <ul>
      {experienceContent.map((val, i) => (
        <li key={i}>
          <div className="icon">
            <i className="fa fa-briefcase"></i>
          </div>
          <span className="time open-sans-font text-uppercase">{val.year}</span>
          <h5 className="poppins-font text-uppercase">
            {val.position}
            <span className="place open-sans-font">{val.compnayName}</span>
          </h5>
          <p className="open-sans-font">{val.details}</p>
        </li>
      ))}
    </ul>
  );
};

export default Experience;
